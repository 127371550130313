import Vue from 'vue'
import ApolloClient from 'apollo-client'
import { WebSocketLink } from 'apollo-link-ws'
import { HttpLink } from 'apollo-link-http'
import { split } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'

const useLocal = true
const isLocal = location.protocol === 'http:' && location.hostname === 'localhost'
const httpUri = useLocal && isLocal ? 'http://localhost:3000/graphql' : `https://api.jam-board.${process.env.CLUSTER}.cds.internal.unity3d.com/graphql`
const wsUri = httpUri.replace(/http|https/g, 'ws')

const authMiddleware = setContext(async () => {
  return {
    headers: {
      Authorization: `Bearer ${await Vue.prototype.$auth.getAccessToken()}`
    }
  }
})

const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true
  }
})

const httpLink = new HttpLink({
  uri: httpUri
})

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  link: authMiddleware.concat(link),
  cache: new InMemoryCache()
})

export default client
