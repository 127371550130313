import gql from 'graphql-tag'

export default gql`
  fragment UserAllFields on User {
    id
    name
    email
    slack_id
    slack_picture
    manager
  }
`
