import sideBarImage from '@/assets/sidebar.png'

const defaultState = () => {
  return {
    title: '',
    sideBarImage,
    drawer: true,
    expandedPanelsEvent: [0, 1],
    showEventDescription: true,
    showOwnedProjects: true,
    showJoinedProjects: true,
    showLikedProjects: true,
    showOtherProjects: true,
    showDroppedProjects: false,
    showActivityBar: true
  }
}

const state = defaultState()

const mutations = {
  SET_TITLE (state, payload) {
    state.title = payload
  },
  SET_DRAWER (state, payload) {
    state.drawer = payload
  },
  SET_EXPANDED_PANELS_EVENT (state, payload) {
    state.expandedPanelsEvent = payload
  },
  SET_SHOW_EVENT_DESCRIPTION (state, payload) {
    state.showEventDescription = payload
  },
  SET_SHOW_OWNED_PROJECTS (state, payload) {
    state.showOwnedProjects = payload
  },
  SET_SHOW_JOINED_PROJECTS (state, payload) {
    state.showJoinedProjects = payload
  },
  SET_SHOW_LIKED_PROJECTS (state, payload) {
    state.showLikedProjects = payload
  },
  SET_SHOW_OTHER_PROJECTS (state, payload) {
    state.showOtherProjects = payload
  },
  SET_SHOW_DROPPED_PROJECTS (state, payload) {
    state.showDroppedProjects = payload
  },
  SET_SHOW_ACTIVITY_BAR (state, payload) {
    state.showActivityBar = payload
  }
}

const actions = {
  SET_TITLE ({ commit }, payload) {
    commit('SET_TITLE', payload)
  },
  SET_DRAWER ({ commit }, payload) {
    commit('SET_DRAWER', payload)
  },
  SET_EXPANDED_PANELS_EVENT ({ commit }, payload) {
    commit('SET_EXPANDED_PANELS_EVENT', payload)
  },
  SET_SHOW_EVENT_DESCRIPTION ({ commit }, payload) {
    commit('SET_SHOW_EVENT_DESCRIPTION', payload)
  },
  SET_SHOW_OWNED_PROJECTS ({ commit }, payload) {
    commit('SET_SHOW_OWNED_PROJECTS', payload)
  },
  SET_SHOW_JOINED_PROJECTS ({ commit }, payload) {
    commit('SET_SHOW_JOINED_PROJECTS', payload)
  },
  SET_SHOW_LIKED_PROJECTS ({ commit }, payload) {
    commit('SET_SHOW_LIKED_PROJECTS', payload)
  },
  SET_SHOW_OTHER_PROJECTS ({ commit }, payload) {
    commit('SET_SHOW_OTHER_PROJECTS', payload)
  },
  SET_SHOW_DROPPED_PROJECTS ({ commit }, payload) {
    commit('SET_SHOW_DROPPED_PROJECTS', payload)
  },
  SET_SHOW_ACTIVITY_BAR ({ commit }, payload) {
    commit('SET_SHOW_ACTIVITY_BAR', payload)
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
