import _ from 'lodash'
import Vue from 'vue'
import { getUsers } from '@/api'

const defaultState = () => {
  return {
    users: [],
    loading: {
      users: null
    }
  }
}

const state = defaultState()

const mutations = {
  SET_LOADING (state, payload) {
    state.loading[payload.key] = payload.value
  },
  SET_USERS (state, payload) {
    state.users = payload
  },
  SET_USER (state, payload) {
    if (state.users[payload.id]) {
      Vue.set(state.users, payload.id, payload)
    }
  },
  REMOVE_USER (state, payload) {
    if (state.users[payload.id]) {
      Vue.delete(state.users, payload.id)
    }
  }
}

const actions = {
  async refreshUsers ({ commit }) {
    commit('SET_LOADING', { key: 'users', value: true })
    try {
      const items = _.keyBy(await getUsers(), 'id')
      commit('SET_USERS', items)
    } catch (error) {
      console.error(error)
    }
    commit('SET_LOADING', { key: 'users', value: false })
  }
}

const getters = {
  usersArray: state => {
    return _.values(state.users)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
