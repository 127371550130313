// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

import oktaConfig from '@/oktaConfig'
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'

import VueLodash from 'vue-lodash'
import _ from 'lodash'

import VueAxios from 'vue-axios'
import axios from 'axios'

import VueMoment from 'vue-moment'
import moment from 'moment'

import Notifications from 'vue-notification'

import VuetifyConfirm from 'vuetify-confirm'

import VuePluralize from 'vue-pluralize'

import VueRandomColor from 'vue-randomcolor'

import VueClamp from 'vue-clamp'
import VueLineClamp from 'vue-line-clamp'
import VueWordCloud from 'vuewordcloud'
import VueMarkdown from 'vue-markdown'
import mavonEditor from 'mavon-editor'
import 'github-markdown-css/github-markdown.css'
import 'mavon-editor/dist/css/index.css'
import { Multipane, MultipaneResizer } from 'vue-multipane'
import VueUploadComponent from 'vue-upload-component'

import VueApollo from 'vue-apollo'
import apolloClient from './apollo'

/**
 * Deep diff between two object-likes
 * @param  {Object} fromObject the original object
 * @param  {Object} toObject   the updated object
 * @return {Object}            a new object which represents the diff
 */

function deepDiff (fromObject, toObject) {
  return _.transform(fromObject, (result, value, key) => {
    if (!_.isEqual(value, toObject[key])) {
      result[key] = _.isObject(value) && _.isObject(toObject[key]) ? _.difference(value, toObject[key]) : value
    }
  })
}
_.mixin({ deepDiff })

const oktaAuth = new OktaAuth(oktaConfig.oidc)
Vue.use(OktaVue, { oktaAuth })

Vue.use(VueLodash, { lodash: _ })
Vue.use(VueAxios, axios)
Vue.use(VueApollo)
Vue.use(VueMoment)
Vue.use(Notifications)
Vue.use(VuetifyConfirm, { vuetify, color: 'primary', icon: 'mdi-alert', title: 'Please confirm action' })
Vue.use(VuePluralize)
Vue.use(VueRandomColor)
Vue.use(VueLineClamp, { importCss: true })
Vue.use(mavonEditor)

Vue.filter('utcAsLocal', (value, format) => {
  return moment.unix(value).local().format(format)
})

Vue.component(VueWordCloud.name, VueWordCloud)
Vue.component('vue-clamp', VueClamp)
Vue.component('vue-markdown', VueMarkdown)
Vue.component('vue-multipane', Multipane)
Vue.component('vue-multipane-resizer', MultipaneResizer)
Vue.component('file-upload', VueUploadComponent)

Vue.config.productionTip = false

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

new Vue({
  apolloProvider,
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
