import Vue from 'vue'
import axios from 'axios'

const useLocal = true
const isLocal = location.protocol === 'http:' && location.hostname === 'localhost'
const apiUrl = useLocal && isLocal ? 'http://localhost:3000' : `https://api.jam-board.${process.env.CLUSTER}.cds.internal.unity3d.com`

export const instance = axios.create({ baseURL: apiUrl })

instance.interceptors.request.use(async request => {
  const accessToken = await Vue.prototype.$auth.getAccessToken()
  request.meta = request.meta || {}
  request.meta.requestStartedAt = new Date().getTime()
  request.headers.Authorization = accessToken ? `Bearer ${accessToken}` : undefined
  return request
})

instance.interceptors.response.use(request => {
  request.config.meta.requestEndedAt = new Date().getTime()
  request.config.meta.requestTookMs = request.config.meta.requestEndedAt - request.config.meta.requestStartedAt
  console.log(`[${request.config.method.toUpperCase()}] Execution time for: ${request.config.url} - ${request.config.meta.requestTookMs} ms`)
  return request
})

Vue.prototype.$api = instance
Vue.prototype.$apiUrl = instance
