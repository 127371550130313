import gql from 'graphql-tag'

export default gql`
  fragment CommentCategoryAllFields on EventCommentCategory {
    id
    name
    display_name
    description
    type
    created
    updated
  }
`
