import _ from 'lodash'
import Vue from 'vue'
import Router from 'vue-router'

import apolloClient from '@/apollo'

import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Login',
          path: '',
          component: () => import('@/views/pages/Login')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: {
        requiresAuth: true
      },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          meta: {
            requiresAuth: true
          },
          component: () => import('@/views/dashboard/Dashboard')
        },
        // Event
        {
          name: 'Event',
          path: '/event/:eventSlug',
          meta: {
            title: null,
            requiresAuth: true
          },
          component: () => import('@/views/dashboard/Event'),
          beforeEnter: async (to, from, next) => {
            try {
              console.log('EVENT ROUTE ENTER')
              // GET EVENT
              const { data: { event } } = await apolloClient.query({
                query: require('@/gql/getEvent').default,
                variables: { slug: to.params.eventSlug }
              })
              if (event) store.dispatch('main/SET_TITLE', event.name)
              return next()
            } catch (error) {
              const graphQLError = _.get(error, ['networkError', 'result', 'errors', 0], undefined)
              if (graphQLError && graphQLError.extensions.code === 'UNAUTHENTICATED') {
                return next({
                  path: '/login',
                  query: { redirect: from.fullPath }
                })
              }
              return next({ path: '/' })
            }
          }
        },
        // Project
        {
          name: 'Project',
          path: '/event/:eventSlug/:projectRandomKey',
          meta: {
            title: null,
            requiresAuth: true
          },
          component: () => import('@/views/dashboard/Project')
        },
        // Report
        {
          name: 'Event Report',
          path: '/report/:eventSlug',
          meta: {
            title: null,
            requiresAuth: true
          },
          component: () => import('@/views/dashboard/Report'),
          beforeEnter: async (to, from, next) => {
            try {
              console.log('REPORT ROUTE ENTER')
              // GET EVENT
              const { data: { event } } = await apolloClient.query({
                query: require('@/gql/getEvent').default,
                variables: { slug: to.params.eventSlug }
              })
              store.dispatch('main/SET_TITLE', event.name)
              return next()
            } catch (error) {
              const graphQLError = _.get(error, ['networkError', 'result', 'errors', 0], undefined)
              if (graphQLError && graphQLError.extensions.code === 'UNAUTHENTICATED') {
                return next({
                  path: '/login',
                  query: { redirect: from.fullPath }
                })
              }
            }
          }
        },
        // Tag Reports by User
        {
          name: 'Tag Reports',
          path: '/report/:eventSlug/user/:userEmail',
          meta: {
            title: null,
            requiresAuth: true
          },
          component: () => import('@/views/dashboard/ReportTagByUser'),
          beforeEnter: async (to, from, next) => {
            try {
              console.log('TAG REPORTS BY USER ROUTE ENTER')
              if (to.params.userEmail === 'me') {
                const user = await Vue.prototype.$auth.getUser()
                to.params.userEmail = user.email
              }
              store.dispatch('main/SET_TITLE', to.params.userEmail.toUpperCase())
              return next()
            } catch (error) {
              const graphQLError = _.get(error, ['networkError', 'result', 'errors', 0], undefined)
              if (graphQLError && graphQLError.extensions.code === 'UNAUTHENTICATED') {
                return next({
                  path: '/login',
                  query: { redirect: from.fullPath }
                })
              }
            }
          }
        },
        // Tag Report
        {
          name: 'Tag Report',
          path: '/report/:eventSlug/tag/:tagName',
          meta: {
            title: null,
            requiresAuth: true
          },
          component: () => import('@/views/dashboard/ReportTag'),
          beforeEnter: async (to, from, next) => {
            try {
              console.log('TAG REPORT ROUTE ENTER')
              store.dispatch('main/SET_TITLE', to.params.tagName.toUpperCase())
              return next()
            } catch (error) {
              const graphQLError = _.get(error, ['networkError', 'result', 'errors', 0], undefined)
              if (graphQLError && graphQLError.extensions.code === 'UNAUTHENTICATED') {
                return next({
                  path: '/login',
                  query: { redirect: from.fullPath }
                })
              }
            }
          }
        }
      ]
    },
    {
      path: '/implicit/callback',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'ImplicitCallback',
          path: '',
          component: () => import('./ImplicitCallback')
        }
      ]
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error')
        }
      ]
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = await Vue.prototype.$auth.isAuthenticated()
    if (!isAuthenticated) {
      return next({
        path: '/login',
        query: { redirect: from.fullPath }
      })
    }
  }
  return next()
})

export default router
