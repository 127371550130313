import gql from 'graphql-tag'

export default gql`
  fragment MilestoneAllFields on EventMilestone {
    id
    color
    date_text
    title
    description
  }
`
