import gql from 'graphql-tag'

export default gql`
  fragment TagAllFields on EventTag {
    id
    name
    display_name
    description
    created
    updated
  }
`
