import _ from 'lodash'
import Vue from 'vue'
import { getTags } from '@/api'

const defaultState = () => {
  return {
    tags: [],
    loading: {
      tags: null
    }
  }
}

const state = defaultState()

const mutations = {
  SET_LOADING (state, payload) {
    state.loading[payload.key] = payload.value
  },
  SET_TAGS (state, payload) {
    state.tags = payload
  },
  SET_TAG (state, payload) {
    if (state.tags[payload.id]) {
      Vue.set(state.tags, payload.id, payload)
    }
  },
  REMOVE_TAG (state, payload) {
    if (state.tags[payload.id]) {
      Vue.delete(state.tags, payload.id)
    }
  }
}

const actions = {
  async refreshTags ({ commit }) {
    commit('SET_LOADING', { key: 'tags', value: true })
    try {
      const items = _.keyBy(await getTags(), 'name')
      commit('SET_TAGS', items)
    } catch (error) {
      console.error(error)
    }
    commit('SET_LOADING', { key: 'tags', value: false })
  }
}

const getters = {
  tagsArray: state => {
    return _.values(state.tags)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
