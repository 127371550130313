<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'App',
  watch: {
    'authState.isAuthenticated': async function (value) {
      console.log('authState.isAuthenticated', value)
      if (value) {
        console.log('Getting claims')
        const claims = await this.$auth.getUser()
        this.LOGIN({ claims })
      } else {
        console.log('Logging out')
        this.LOGOUT()
        this.$router.push({ path: '/' })
      }
    }
  },
  created () {
    if (window.location.host.startsWith('idea-registry')) {
      window.location.replace(window.location.href.replace('idea-registry', 'jam-board'))
    }
  },
  methods: {
    ...mapActions('user', ['LOGIN', 'LOGOUT'])
  }
}
</script>

<style lang="scss">
  // generate text opacity classes
  $base: 0;
  @while $base < 1 {
    $base: $base + 0.05;
    .o-#{$base * 100} {
      opacity: #{$base};
    }
  }
  // generate backdrop classes
  @for $i from 1 through 9 {
    .backdrop-#{$i} {
      background-color: rgba(128, 128, 128, $i / 10);
    }
  }
  //
  .blockquote {
    border-left: 8px solid #eee;
  }
  .v-card.text--disabled {
    img {
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }
  .clickable {
    cursor: pointer;
  }
  // Multipane resizer
  .multipane.foo.layout-v .multipane-resizer {
    margin: 0; left: 0; /* reset default styling */
    width: 15px;
    background: blue;
  }

  .multipane.foo.layout-h .multipane-resizer {
    margin: 0; top: 0; /* reset default styling */
    height: 15px;
    background: blue;
  }
  .multipane.vertical-panes {
    width: 100%;
    border: 1px solid #ccc;
  }
  .multipane.vertical-panes > .pane {
    text-align: left;
    padding: 15px;
    overflow: hidden;
    background: #eee;
  }
  .multipane.vertical-panes > .pane ~ .pane {
    border-left: 1px solid #ccc;
  }
  // A hack that seems to fix markdown-body code snippets
  .markdown-body {
    video {
      max-width: 100%;
    }
    pre code {
      display: inline-flex !important;
      width: 300px !important;
    }
  }
  // Hide upload image button from markdown editor & adjust border color & adjust height
  .v-note-wrapper {
    min-height: 128px !important;
    z-index: 5 !important;
    border: 1px solid #aaa !important;
    .v-note-op {
      border-bottom: 1px solid #aaa !important;
    }
    /* .op-image.popup-dropdown {
      :nth-child(2) {
        display: none !important;
      }
    } */
  }
  // Pulse animation
  @keyframes pulse {
    from { transform: scale(1); }
    50% { transform: scale(1.2); }
    to { transform: scale(1); }
  }
  .pulse {
    animation-name: pulse;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
  }
  @keyframes pulse-medium {
    from { transform: scale(1); }
    50% { transform: scale(1.05); }
    to { transform: scale(1); }
  }
  .pulse-medium {
    animation-name: pulse-medium;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
  }
  @keyframes pulse-small {
    from { transform: scale(1); }
    50% { transform: scale(1.01); }
    to { transform: scale(1); }
  }
  .pulse-small {
    animation-name: pulse-small;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
  }
  // Add flip-list transition
  .flip-list {
    &-move {
      transition: transform 0.8s !important;
    }
    &-enter,
    &-leave-to {
      opacity: 0 !important;
      transform: translateY(50px) !important;
    }
    &-enter-active {
      transition: all 0.4s ease !important;
    }
    &-leave-active {
      position: absolute !important;
      width: 100% !important;
      transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1) !important;
    }
  }
  // Add flip-grid transition
  .flip-grid {
    &-move {
      transition: transform 0.8s !important;
    }
    &-enter,
    &-leave-to {
      opacity: 0 !important;
      transform: translateY(30px) !important;
    }
    &-enter-active {
      transition: all 0.4s ease !important;
    }
    &-leave-active {
      position: absolute !important;
      width: 100% !important;
      transition: all 0s;
    }
  }
</style>
