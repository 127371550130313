<template>
  <v-list-item
    :active-class="`primary ${!isDark ? 'black' : 'white'}--text`"
    :to="to"
  >
    <v-list-item-content v-if="item.name">
      <v-list-item-title>
        {{ item.name }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <div class="d-flex align-center justify-end">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="currentUserIsOwner"
              class="mr-1"
              :loading="loading.delete"
              x-small
              text
              fab
              @click.prevent=""
              v-on="on"
            >
              <v-icon small>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="() => {}"
            >
              <v-list-item-title>
                Edit Event
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="deleteEvent(!item.is_enabled)"
            >
              <v-list-item-title>
                {{ item.is_enabled ? 'Mark Event as Dropped' : 'Mark Event as Active' }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-chip
          color="primary lighten-1"
          class="my-2 px-2"
          x-small
        >
          {{ item.projects_count }}
        </v-chip>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { updateEvents } from '@/api'

import Themeable from 'vuetify/lib/mixins/themeable'

export default {
  name: 'EventItem',

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    to: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    loading: {
      delete: false
    }
  }),

  computed: {
    ...mapState('user', ['claims']),
    // Ownership
    currentUserIsOwner () {
      return this.item.owner.id === this.claims.sub
    }
  },

  methods: {
    ...mapMutations('events', { SET_EVENT: 'SET_EVENT' }),
    async deleteEvent (newStatus) {
      this.loading.delete = true
      try {
        await updateEvents(
          [
            {
              id: this.item.id,
              is_enabled: newStatus
            }
          ]
        )
        this.SET_EVENT({ ...this.item, is_enabled: newStatus })
      } catch (error) {
        console.error(error)
      }
      this.loading.delete = false
    }
  }

}
</script>
