import gql from 'graphql-tag'
import UserAllFields from './UserAllFields'
import TagAllFields from './TagAllFields'
import CommentCategoryAllFields from './CommentCategoryAllFields'
import CommentResolutionAllFields from './CommentResolutionAllFields'
import MilestoneAllFields from './MilestoneAllFields'

export default gql`
  fragment EventAllFields on Event {
    id
    name
    description
    slug
    fogbugz_tag
    owner {
      ...UserAllFields
    }
    curated_tags
    show_document_field
    show_github_repository_field
    show_slack_channel_field
    show_video_field
    min_participants
    preferred_participants
    max_participants
    is_private
    is_draft
    is_active
    is_enabled
    tags {
      ...TagAllFields
    }
    moderators {
      id
      user {
        ...UserAllFields
      }
    }
    users {
      id
      user {
        ...UserAllFields
      }
    }
    commentCategories {
      ...CommentCategoryAllFields
    }
    commentResolutions {
      ...CommentResolutionAllFields
    }
    milestones {
      ...MilestoneAllFields
    }
  }
  ${UserAllFields}
  ${TagAllFields}
  ${CommentCategoryAllFields}
  ${CommentResolutionAllFields}
  ${MilestoneAllFields}
`
