import gql from 'graphql-tag'
import EventAllFields from './fragments/EventAllFields'

export default gql`
  query getEvent ($slug: String!) {
    event (slug: $slug) {
      ...EventAllFields
    }
  }
  ${EventAllFields}
`
