import gql from 'graphql-tag'

export default gql`
  fragment CommentResolutionAllFields on EventCommentResolution {
    id
    title
    description
    created
    updated
  }
`
